export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40')
];

export const server_loads = [0,2];

export const dictionary = {
		"/": [6],
		"/(content)/about-us": [14],
		"/(admin)/admin": [7,[2]],
		"/booking/[token]": [~34],
		"/booking/[token]/cancel/[cancelToken]": [~35],
		"/booking/[token]/validate": [~36],
		"/(extra)/cookiePolicy": [32],
		"/(content)/faq": [15],
		"/(app)/home": [8,[3,4]],
		"/(app)/home/calendar": [9,[3,4]],
		"/(app)/home/profile": [~10,[3,4]],
		"/(app)/home/settings": [11,[3,4]],
		"/(app)/intro": [~12,[3]],
		"/(app)/logging-in": [13,[3]],
		"/login": [37],
		"/(content)/pricing": [16],
		"/(extra)/privacy": [33],
		"/search": [~38],
		"/(content)/solution": [17],
		"/theme": [39],
		"/therapist/[...url]": [~40],
		"/(content)/therapy/acupuncture": [18,[5]],
		"/(content)/therapy/aromatherapy": [19,[5]],
		"/(content)/therapy/ayurveda": [20,[5]],
		"/(content)/therapy/chinese-medicine": [21,[5]],
		"/(content)/therapy/chiropratic": [22,[5]],
		"/(content)/therapy/herbal-medicine": [23,[5]],
		"/(content)/therapy/homeopathy": [24,[5]],
		"/(content)/therapy/hypnosis": [25,[5]],
		"/(content)/therapy/meditation": [26,[5]],
		"/(content)/therapy/naturopathy": [27,[5]],
		"/(content)/therapy/nutrition-diet": [28,[5]],
		"/(content)/therapy/osteopathy": [29,[5]],
		"/(content)/therapy/reflexology": [30,[5]],
		"/(content)/therapy/reiki": [31,[5]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
	
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';